<div class="popup-container">
  <div (click)="cancel()" class="popup-background"></div>
  <div class="popup-content">
    <div class="popup-head">
      <div class="popup-close">
        <img (click)="cancel()" class="popup-close-icon" src="https://www.mixtiles.com/images/icons/xIcon.svg">
      </div>
      <h3 class="popup-title">{{title}}</h3>
      <div class="popup-accept">
        <button *ngIf="!!acceptText" (click)="accept()" class="popup-accept-button">{{acceptText}}</button>
      </div>
    </div>
    <div class="popup-body">
      <ng-content select=".popup-body"></ng-content>
    </div>
    <div class="popup-footer">
      <ng-content select=".popup-footer"></ng-content>
    </div>
  </div>
</div>
