import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enum',
  templateUrl: './enum.component.html',
  styleUrls: ['./enum.component.scss']
})
export class EnumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
