<div *ngIf="!!pageContent" class="register-page">
  <app-header [menus]="headerMenuPopup"></app-header>
  <div class="register-content" [style.backgroundImage]="'url(\''+pageContent.backgroundImageUrl+'\')' | safe:'style'">
    <div class="form-container">
      <div class="register-form">
        <h3 class="form-title">{{pageContent.title}}</h3>
        <div class="form-body">
          <input [(ngModel)]="formData.firstName" class="form-control"
                 [placeholder]="pageContent.controls.inputFirstName.placeholder">
          <input [(ngModel)]="formData.lastName" class="form-control"
                 [placeholder]="pageContent.controls.inputLastName.placeholder">
          <input [(ngModel)]="formData.email" class="form-control"
                 [placeholder]="pageContent.controls.inputEmail.placeholder">
        </div>
        <button (click)="submit();" class="btn-submit">{{pageContent.btnSubmit.text}}</button>
      </div>
    </div>
  </div>
</div>
