<div *ngIf="!!pageContent && !isHeaderWeb" class="header">
  <div class="header-left">
    <img (click)="goBack()" class="header-left-icon" [src]="pageContent.backIconUrl">
  </div>
  <div class="header-center">
    <h3 class="title">{{title}}</h3>
  </div>
  <div class="header-right">
<!--    <img (click)="showMenuHead=true;" class="header-right-icon" [src]="pageContent.menuIconUrl">-->
<!--    <div *ngIf="showMenuHead" class="head-menus">-->
<!--      <div (click)="showMenuHead=false" class="head-menus-bg"></div>-->
<!--      <div class="head-menus-content">-->
<!--        <ul class="menu-list">-->
<!--          <li *ngFor="let menu of menus.menus" class="menu-item">-->
<!--            <a (click)="acceptMenu(menu);" class="menu-link">{{menu.name}}</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<div *ngIf="!!pageContent && !!isHeaderWeb">
  <div class="top-menu">
    <a href="/">
      <img [src]="pageContent.webMenu.logo"></a>
    <div class="menu-items">
      <div class="menu-item" *ngFor="let menu of pageContent.webMenu.menus">
        <a [href]="menu.link" target="_self">{{menu.name}}</a>
      </div>
    </div>
  </div>
</div>
<!--Frequent Questions-->
<app-popup [acceptText]="false" [title]="menus.questions.title" (cancelEvent)="showQuestionPopup=false;"
           *ngIf="!!menus && showQuestionPopup">
  <div class="questions popup-body">
    <div *ngFor="let item of menus.questions.contents" class="question-item">
      <h3 class="question-title">{{item.question}}</h3>
      <p class="question-content">{{item.answer}}</p>
    </div>
  </div>
</app-popup>

<!--Frequent Questions-->
<app-popup [acceptText]="menus.promotion.done" [title]="menus.promotion.title"
           (acceptEvent)="showPromotionPopup=false;applyPromotionCode();"
           (cancelEvent)="showPromotionPopup=false;"
           *ngIf="!!menus && showPromotionPopup">
  <div class="promotion-add popup-body">
    <label class="pro-label">{{menus.promotion.label}}</label>
    <input class="pro-input" [placeholder]="menus.promotion.inputPlaceholder" [(ngModel)]="promotionCode">
  </div>
</app-popup>
