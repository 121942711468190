<ng-container *ngIf="!!pageContent">
  <div class="section-menu-bottom-desktop">
    <ul class="menu-list">
      <li [ngClass]="{'mobile-hide':!menu.showMobile}" class="menu-item"
          *ngFor="let menu of pageContent.sectionMenuBottom.menus">
        <a [routerLink]="[menu.link]" class="menu-link">{{menu.name}}</a>
      </li>
    </ul>
    <ul class="menu-list-mini mobile-show desktop-hide">
      <li [ngClass]="{'mobile-show':!menu.showMobile}" class="menu-item-mini"
          *ngFor="let menu of pageContent.sectionMenuBottom.menus">
        <a [routerLink]="[menu.link]" class="menu-link-mini">{{menu.name}}</a>
      </li>
    </ul>
    <p class="copyright mobile-show desktop-hide">{{pageContent.sectionMenuBottom.copyright}}</p>
  </div>

  <div class="section-language">
    <ng-container *ngFor="let lang of pageContent.languages">
      <button (click)="languagePopupShow=true;" class="btn-lang" *ngIf="currentLanguage===lang.code">
        <img class="icon" [src]="lang.iconUrl">
        <span class="name">{{lang.name}}</span>
        <span class="arrow"></span>
      </button>
    </ng-container>
  </div>


  <app-popup [title]="'Chọn ngôn ngữ'"
             (cancelEvent)="languagePopupShow=false;" *ngIf="languagePopupShow"
             [acceptText]="''">
    <div class="languages-choose popup-body">
      <div (click)="selectLanguage(lang)" *ngFor="let lang of pageContent.languages" class="lang-item">
        <img class="icon" [src]="lang.iconUrl">
        <span class="name">{{lang.name}}</span>
      </div>
    </div>
  </app-popup>
</ng-container>


