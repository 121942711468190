<div *ngIf="pageContent" class="home-page">
  <div class="section-one">
    <img [src]="pageContent.logoUrl | safe:'url'" class="logo">
    <h1 class="title">{{pageContent.description}}</h1>
    <div class="video-container">
      <iframe
        width="{{(window.innerWidth-20)>800?800:(window.innerWidth-20)}}"
        height="{{((window.innerWidth-20)>800?800:(window.innerWidth-20))*450/800}}"
        class="video-iframe" [src]="pageContent.videoUrl | safe:'resourceUrl'"></iframe>
    </div>
    <!-- <div class="video-desc">
      <p class="video-des-text">{{pageContent.descriptionVideo}}</p>
      <img class="video-desc-icon" [src]="pageContent.videoIconUrl">
    </div> -->
  </div>

<!--  <div class="section-price section-bg-white">-->
<!--    <h3 class="title">{{pageContent.sectionPrice.title}}</h3>-->
<!--    <p class="desc">{{pageContent.sectionPrice.description}}</p>-->
<!--  </div>-->

  <div class="section-review">
    <h3 class="review-title">{{pageContent.sectionReviews.title}}</h3>
    <p class="review-desc">{{pageContent.sectionReviews.desc}}</p>
    <div class="reviews swiper-container">
      <div class="swiper-wrapper">
        <div class="review-item swiper-slide" *ngFor="let review of pageContent.sectionReviews.reviews">
          <img class="review-image" [src]="review.imageUrl">
<!--          <div class="review-info">-->
<!--            <img class="review-source" [src]="review.sourceIconUrl">-->
<!--            <span class="review-author">{{review.authorName}}</span>-->
<!--            <p class="review-comment">{{review.comment}}</p>-->
<!--          </div>-->
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>

<!--  <div class="section-print section-bg-white">-->
<!--    <h3 class="title">{{pageContent.sectionPrint.title}}</h3>-->
<!--    <p class="desc">{{pageContent.sectionPrint.description}}</p>-->
<!--  </div>-->

<!--  <div class="section-rate">-->
<!--    <img class="rate-img" [src]="pageContent.sectionRate.imageUrl">-->
<!--    <p class="rate-info">{{pageContent.sectionRate.info}}</p>-->
<!--    <h3 class="rate-desc">{{pageContent.sectionRate.description}}</h3>-->
<!--  </div>-->

  <app-footer></app-footer>

  <div class="footer">
    <button [routerLink]="['/getstarted']"
            class="btn-start">{{(!!countImagesExt ? (pageContent.startButtonExt + ' (' + countImagesExt + ')') : pageContent.startButton)}}</button>
  </div>
</div>
