<app-header [isHeaderWeb]="true"></app-header>

<div *ngIf="!!pageContent" class="news-page">
  <img class="team-photo" [src]="pageContent.banner">
  <div class="news-departments">
    <div class="newsDepartment">
      <div class="department-name">{{pageContent.title}}</div>
      <div class="newsPosition" [innerHTML]="pageContent.content |safe:'html'">
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
