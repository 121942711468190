<app-popup [title]="pageContent.title"
           [acceptText]="pageContent.done"
           (acceptEvent)="accept()"
           (cancelEvent)="cancel()">
  <div class="design-edit-content popup-body">
    <h3 class="design-image-title">{{pageContent.pinchAndZoom}}</h3>
    <div class="design-image-edit">
      <div class="pinch-zoom-container">
        <img class="image-background" [ngClass]="{'full-frame-content': imageFrame.code == 'full'}" [src]="imageFrame.frameUrl">
        <div class="pinch-zoom-view" [ngClass]="{'padding':imageFrame.isPadding}">
          <div class="pinch-zoom-view2">
            <div #pinchZoomElement class="pinch-zoom" [ngClass]="{'full-frame-zoom': imageFrame.code == 'full'}">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</app-popup>

