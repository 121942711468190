<div *ngIf="!!pageContent" class="design-page">
  <app-header [linkBack]="''" [menus]="headerMenuPopup"></app-header>
  <div class="design-content">
    <div class="design-header">
      <div class="list-styles">
        <ng-container *ngFor="let style of pageContent.styles">
          <div (click)="styleCodeSelected=style.code;selectCode()"
               [ngClass]="{'selected':styleCodeSelected===style.code}"
               class="style-item">
            <img class="style-icon" [src]="style.iconUrl">
            <p class="style-name">{{style.name}}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="design-body"
         [style.backgroundImage]="(images.length===0?('url(\''+pageContent.backgroundImageUrl+'\')'):'none') | safe:'style'"
         [style.backgroundColor]="(images.length>0?'#ededed':'none')">

      <input multiple="multiple" accept="image/png, image/jpeg"
             (change)="handleFileInput($event.target.files); fileUploadHidden.value = '';" #fileUploadHidden type="file"
             style="display: none">
      <div class="list-images" [style.width]="(images.length*279+251)+'px'">
        <ng-container *ngFor="let image of images">
          <app-image [image]="image" [imageFrame]="imageFrame()" (selectEvent)="selectImage(image);"></app-image>
        </ng-container>
        <div class="image-item-add" (click)="fileUploadHidden.click();">
          <div class="add-front">
            <svg class="icon-add" viewBox="0 0 37.76 38.93">
              <path fill="rgb(176,176,176)" class="plus-shape"
                    d="M21.22,0V17.2H37.76v4.39H21.22V38.93H16.54V21.59H0V17.2H16.54V0Z"></path>
            </svg>
          </div>
          <div class="add-back">
            <img class="upload-icon" [src]="pageContent.upload.iconUrl">
            <p class="upload-text">{{pageContent.upload.text}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="design-footer">
      <button (click)="openCheckout();" class="btn-submit">{{pageContent.btnSubmit.text}}</button>
    </div>
  </div>

  <app-menu *ngIf="editMenuPopup.show"
            (actionEvent)="editMenuAction($event)"
            [menus]="editMenuPopup.menus"></app-menu>

  <app-menu *ngIf="lowQualityMenuPopup.show"
            (actionEvent)="lowQualityMenuAction($event)"
            [menus]="lowQualityMenuPopup.menus"
            [popupTop]="lowQualityMenuPopup"
            [image]="lowQualityMenuPopup.image"></app-menu>


  <app-zoom [dataInit]="pageContent.zoomPopup"
            [image]="getSelectedImage()"
            [imageFrame]="imageFrame()"
            (acceptEvent)="popupEdit.show=false;updateImageEdit($event)"
            (cancelEvent)="popupEdit.show=false;" *ngIf="popupEdit.show"></app-zoom>

  <app-checkout [images]="images"
                [imageFrame]="imageFrame()"
                (checkoutEvent)="checkoutAction($event)"
                [dataInit]="pageContent.checkoutPopup"
                *ngIf="checkoutPopup.show"></app-checkout>
</div>


